var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warpConss"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.openIndex),callback:function ($$v) {_vm.openIndex=$$v},expression:"openIndex"}},_vm._l((_vm.CourseList),function(grounp,grounpIndex){return _c('el-collapse-item',{key:grounpIndex,attrs:{"name":grounpIndex}},[_c('template',{slot:"title"},[_c('div',{staticClass:"title-grounp"},[_c('img',{staticClass:"title-icon",attrs:{"src":require("@/assets/img/Home/zy.png"),"alt":""}}),_c('span',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(grounp.title))]),(
              grounp.audition != '0' &&
                !_vm.IsPurchase &&
                grounp.audition != '' &&
                grounp.audition != null
            )?_c('span',{staticClass:"tryListion"},[_vm._v("可试看")]):_vm._e()])]),_vm._l((grounp.list),function(item,index){return _c('div',{key:index,staticClass:"chat-item",class:(item.audition && item.audition != '0') || _vm.IsPurchase
            ? 'canVideo'
            : ''},[_c('div',{staticClass:"item-title",on:{"click":function($event){$event.stopPropagation();return _vm.check(item, grounp, grounpIndex)}}},[((item.audition && item.audition != '0') || _vm.IsPurchase)?_c('i',{staticClass:"iconfont icon-a-Union21"}):_c('i',{staticClass:"iconfont icon-suotou"}),_c('div',{staticClass:"title ellipsis"},[_vm._v(" "+_vm._s(item.title)+" ")]),(
              item.audition != '0' &&
                !_vm.IsPurchase &&
                item.audition != '' &&
                item.audition != null
            )?_c('span',{staticClass:"tryListion"},[_vm._v("试看")]):_vm._e()]),_c('div',{staticClass:"but-list"},[_c('div',[(
                _vm.ifClass ? _vm.dealDocType(item, 2) : item.isHasContantJY == 'true'
              )?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.ktData(item, 5)}}},[_vm._v(" 随堂讲义 ")]):_vm._e(),(
                _vm.ifClass
                  ? item.contactList && item.contactList.length > 0
                  : item.isHasContant == 'true'
              )?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.cgeckTask(item)}}},[_vm._v(" 随堂测试 ")]):_vm._e(),(_vm.ifClass ? _vm.dealDocType(item, 1) : item.isHasDoc == 'true')?_c('div',{staticClass:"keep-learn",on:{"click":function($event){$event.stopPropagation();return _vm.ktData(item, 3)}}},[_vm._v(" 随堂资料 ")]):_vm._e()])])])})],2)}),1),(_vm.dialogVisible)?_c('div',{staticClass:"warpCpons",on:{"touchmove":function($event){$event.stopPropagation();$event.preventDefault();return _vm.stop()}}},[_c('div',[_c('img',{staticClass:"imga",attrs:{"src":require("@/assets/img/kecheng/No1.png")}}),_c('img',{staticClass:"imgb",attrs:{"src":require("@/assets/img/kecheng/close.png")},on:{"click":function($event){$event.stopPropagation();return _vm.tChuang.apply(null, arguments)}}}),_c('span',[_vm._v("您暂未购买该课程哦~")])])]):_vm._e(),_c('el-dialog',{attrs:{"title":"","custom-class":"dpPlay","visible":_vm.ifDialog,"show-close":false,"width":"80%","append-to-body":""},on:{"update:visible":function($event){_vm.ifDialog=$event}}},[_c('iframe',{attrs:{"src":`${_vm.href}/pages/videoRoom?videoId=${_vm.id}&id=${
        _vm.dialogList ? _vm.dialogList.id : ''
      }&domainjwt=${_vm.domainjwt}&userId=${
        _vm.userInfo ? _vm.userInfo.id : ''
      }&classId=${_vm.classId}`,"width":"100%","height":"721px","frameborder":"0","noResize":""}})]),(_vm.showLookDetail)?_c('div',{staticClass:"lookBg"},[_c('div',{staticClass:"ifLook"},[_c('div',{staticClass:"prompt"},[_vm._v("提示")]),_c('div',{staticClass:"contents"},[_vm._v(" 您当前正在"),_c('span',[_vm._v(_vm._s(_vm.lookDeatil ? _vm.lookDeatil.terminal : ""))]),_vm._v("观看"),_c('span',[_vm._v(_vm._s(_vm.lookDeatil ? _vm.lookDeatil.chapterName : ""))]),_vm._v(",请关闭后再行观看其他章节。 ")]),_c('div',{staticClass:"button",on:{"click":_vm.closeLook}},[_vm._v("关闭")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }