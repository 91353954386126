<template>
  <div class="warp">
    <!-- 头部 -->
    <div class="warpTop">
      <div class="path"><span @click="saveImg('/')">首页</span></div>
      <div class="warpTopsCent">
        <!-- 左侧视频 -->
        <img class="warpTopsCentL" :src="video.img">

        <!-- 右侧详情 -->
        <div class="warpTopsCentR">
          <!-- 标题 -->
          <div class="title dis_flex">
            <!-- <div class="courType">录播</div> -->
            {{ video.title }}
          </div>
          <div class="detail-ter m-t-20 flex-c">
            <div class="ter-list">
              <span class="ter">主讲老师：</span>
              <div class="terbox" @click="goNext(`/faculty/teacherDetail?id=${video.teacherId}`)">
                <el-image class="ter-img" :src="video ? video.tImg : ''" fit="cover" />
              </div>
              <!--              <span class="ter">讲师：{{ video ? video.terName : "" }}</span>-->
            </div>
            <!--            <el-divider direction="vertical"></el-divider>-->
            <!--            <el-rate-->
            <!--              :value="average"-->
            <!--              disabled-->
            <!--              :colors="['#99A9BF', '#F7BA2A', '#FF9900']"-->
            <!--            >-->
            <!--            </el-rate>-->
          </div>
          <div class="vip_box">
            <div class="content_box">
              <div class="vip_content">录播</div>
              <div class="vip_content">{{ video.kcName }}</div>
              <div class="vip_content">{{ video.allTime }}课时</div>
            </div>
          </div>
          <!-- 价格 -->
          <div v-if="priceShow">
            <div class="picr">
              <div class="spanb"><span>￥</span>{{ video.jgPrice ? video.jgPrice : 0 }}</div>
              <div class="btn">
                <div v-if="!IsPurchase" class="keep-learn keep-check" @click="yuyue(1)">
                  立即购买
                </div>
                <div v-else class="keep-learn" @click="yuyue(2)">已购买</div>
              </div>
              <!-- <div class="oldprice">￥{{ video.olderPrice ? video.olderPrice : 0 }}</div> -->
            </div>
            <!-- <div>
              <div>
                <span>领券</span>
                <span></span>
              </div>
              <div>
                <span>有效期</span>
              </div>
            </div> -->
            <!-- 报名资讯按钮 -->
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box-content">
        <!-- 选项栏 -->
        <div class="warpnav">
          <div class="warpnavs">
            <div class="warpnavsCons" :class="navIndex == 1 ? 'on' : ''" @click="navs(1)">
              <span>课程详情</span>
              <div v-if="navIndex == 1" />
            </div>

            <div class="warpnavsCons" :class="navIndex == 2 ? 'on' : ''" @click="navs(2)">
              <span>课程列表</span>
              <div v-if="navIndex == 2" />
            </div>
            <div v-if="productLine == 2" class="warpnavsCons" :class="navIndex == 3 ? 'on' : ''" @click="navs(3)">
              <span>课程资料</span>
              <div v-if="navIndex == 3" />
            </div>
            <div v-if="productLine == 2" class="warpnavsCons" :class="navIndex == 4 ? 'on' : ''" @click="navs(4)">
              <span>课程测试</span>
              <div v-if="navIndex == 4" />
            </div>
            <div class="warpnavsCons" :class="navIndex == 5 ? 'on' : ''" @click="navs(5)">
              <span>课程评价 ({{ evaluate }})</span>
              <div v-if="navIndex == 5" />
            </div>
          </div>
        </div>
        <div class="dis_flex_start">
          <div v-if="navIndex == 1" class="warpCon">
            <div class="apple">
              <div class="appleLeft">
                <div class="appleLefts" v-html="ImgSizeLimit(video.content)" />
              </div>
              <!-- 右侧推荐 -->
              <!-- <you-love :id="zhiboId" :type="3" /> -->
            </div>
          </div>
          <!-- 录播章节 -->
          <div v-if="navIndex == 2" class="warpCon">
            <video-chater :id="zhiboId" :is-outsourcing="video.isOutsourcing" :class-id="classId ? classId : ''"
              :class-product-line="classProductLine" :is-purchase="IsPurchase ? true : video.jgPrice > 0 ? false : true"
              :list="video.list" :is-freeze="isFreeze" />
          </div>
          <!-- 课程资料 -->
          <div v-if="navIndex == 3" class="warpCon">
            <course-data v-if="navIndex == 3" :id="zhiboId" :type="2" :is-purchase="IsPurchase" />
          </div>
          <!-- 课程模考 -->
          <div v-if="navIndex == 4" class="warpCon">
            <course-task v-if="navIndex == 4" :id="zhiboId" :type="2" :is-purchase="IsPurchase" />
          </div>
          <!-- 课程评价 -->
          <div v-if="navIndex == 5" class="warpCon">
            <score-evaluation v-if="navIndex == 5" :type="3" :type-id="zhiboId" :type-nmae="video.title"
              :is-purchase="IsPurchase" :course-data="video" />
          </div>
          <you-love :id="zhiboId" class="youlove" :ifteacher="true" :type="3" />
        </div>
        <!-- 课程详情内容 -->
      </div>
    </div>

    <div v-if="isFreeze == 1" class="mask_layer">课程已冻结，请联系老师</div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { checkStudyCode } from '@/api/home.js'
import { getClassCourseById } from '@/api/home'
import VideoChater from '@/components/Know/videoChaterPartner.vue'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { getToken } from '@/api/cookies'
import YouLove from '@/components/Know/youLove.vue'
import CourseData from '@/components/Know/course/courseDataPartner.vue'
import courseTask from '@/components/Know/course/courseTaskPartner.vue'
import scoreEvaluation from '@/components/Know/course/scoreEvaluationPartner.vue'
import { Evaluation } from '@/api/scoreEvaluation'
const evaluation = new Evaluation()
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { VideoChater, YouLove, CourseData, courseTask, scoreEvaluation },

  data() {
    return {
      video: {},
      navIndex: 1,
      yuyues: 2,
      IsPurchase: false, // 章节列表
      zhiboId: null,
      classId: null,
      average: 0, // 评分
      productLine: null,
      cardCode: null,
      classProductLine: 0,
      isFreeze: null, // 是否冻结 0 否 1 是

      priceShow: true
    }
  },
  created() {
    this.classProductLine = this.$route.query.classProductLine
      ? Number(this.$route.query.classProductLine)
      : 0
    this.cardCode =
      this.$route.query.cardCode == 'undefined'
        ? null
        : this.$route.query.cardCode
    this.checkStudyCode()
    this.classId =
      this.$route.query.classId &&
        this.$route.query.classId != 'undefined' &&
        this.$route.query.classId != 'null' &&
        Number(this.$route.query.classId) != NaN
        ? this.$route.query.classId
        : null
    this.zhiboId = this.$route.query.id
    this.loadgetDetail()
    evaluation.getPresentScore(3, this.zhiboId).then((res) => {
      this.average = Math.round(res.average)
    })
    know.updateCourseAllClicks(3, this.zhiboId)

    this.priceShow = this.$route.query.vip != 'true'
  },
  methods: {
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode
        }
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true
            this.$store.commit('IS_PURCHASE_CHANGE', true)
          }
        })
      }
    },
    // 录播课程详情信息
    loadgetDetail() {
      getClassCourseById(this.zhiboId, this.classId).then((res) => {
        if (!this.cardCode) {
          this.IsPurchase = res.data.IsPurchase
        }
        this.productLine = res.data.details.productLine
        this.video = res.data.details
        this.video.size = res.data.size
        this.video.price = res.data.details.jgPrice
        this.video.jgPrice = res.data.jgPrice
        this.isFreeze = res.data.isFreeze
        // this.productLine = res.data.details.productLine;
        evaluation
          .getStudentEvaluateList(
            999999,
            1,
            3,
            this.zhiboId,
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          )
          .then((res) => {
            this.evaluate = res.total
          });
      })
    },
    // 预约按钮
    yuyue(index) {
      this.yuyues = index
      if (index == 1) {
        const token = getToken()
        if (token) {
          orderClient
            .addOrders(
              this.video.id,
              localStorage.getItem('userId'),
              this.video.title,
              3,
              this.video.title,
              this.video.kind
            )
            .then((res) => {
              if (res.code == 0) {
                /*   this.users = res.msg; */
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          Vue.prototype.goLoginView(true)
          // this.$router.push({ name: "登录页", params: { hzc: 1 } });
        }
      }
    },
    // 立即录播按钮
    zhiboBut() {
      const token = getToken()
      if (token) {
        this.$router.push({
          name: '录播课播放',
          params: {
            id: this.video.id,
            ccLiveid: this.video.ccLiveid,
            ccRomid: this.video.ccRomid,
            recordId: this.video.recordId,
            videos: this.video.aliVideoUrl,
            titles: this.video.title
          }
        })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val
    },
    // 观看重播按钮  切换课程章节播放
    gkcb(v) {
      this.$router.replace(
        `/zhibobfang?id=${this.$route.params.id}&ids=${v.id}&typebs=1`
      )
    }
  }
}
</script>
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  overflow: hidden;

  // 头部
  .warpTop {
    width: 100%;
    height: 230px;
    background: #375669;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    padding: 0px calc(50% - 600px);

    .path {
      width: 1200px;
      height: 56px;
      padding: 20px 0;
      margin: 0 auto;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .warpTopsCent {
      width: 100%;
      height: 142px;
      display: flex;
      justify-content: flex-start;
      align-items: start;

      // 左侧视频
      .warpTopsCentL {
        width: 252px;
        height: 142px;
        border-radius: 6px;
        opacity: 1;
        margin-right: 20px;
        flex-shrink: 0;
      }

      // 右侧详情
      .warpTopsCentR {
        width: 100%;
        height: 100%;
        position: relative;

        // 标题
        .title {
          margin-top: 10px;
          height: 36px;
          line-height: 36px;
          font-size: 24px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;

          .courType {
            height: 24px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #ff7b1c;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 22px;
            padding: 0px 11px;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }

        .vip_box {
          margin-top: 28px;

          .content_box {
            display: flex;
            margin-bottom: 24px;

            .vip_content {
              padding: 0 6px;
              height: 22px;
              line-height: 22px;
              margin-right: 10px;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #fff;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }

            .m_r_2 {
              margin-right: 2em;
            }

            .em {
              letter-spacing: 2em;
            }
          }
        }

        // 价格
        .picr {
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .spanb {
            height: 36px;
            font-size: 24px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #f9431e;
            margin-right: 18px;

            span {
              font-size: 16px;
            }
          }

          .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            // 立即报名
            .keep-learn {
              width: 130px;
              height: 44px;
              line-height: 44px;
              border-radius: 22px;
              background: linear-gradient(47deg, #ff6161 0%, #f52828 100%);
              box-shadow: 0px 3px 6px 1px rgba(246, 43, 43, 0.3);
              opacity: 1;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
            }
          }
        }

        // 报名资讯按钮

        //主讲老师
        .ter-list {
          .ter {
            color: #ffffff;
          }
        }
      }
    }
  }

  // 选项栏
  .warpnav {
    margin: 0px auto;
    width: 1200px;
    height: 48px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding-left: 20px;
    line-height: 48px;

    // 缩宽
    .warpnavs {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // 知识套餐概述
      /*  .warpnavsCons {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      } */
      // 竖线

      // 转换内容 选中
      .on {
        height: 100%;

        span {
          font-weight: bold !important;
          color: #333333;
        }

        div {
          width: 20px;
          height: 4px;
          background: #1e82f5;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          margin-left: calc(50% - 10px);
          margin-top: -3px;
        }
      }

      // 转换内容 未选中
      .warpnavsCons {
        height: 100%;
        margin-right: 40px;

        span {
          height: 16px;
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
      }
    }
  }

  // 直播课内容
  .warpCon {
    width: 852px;
    min-height: 593px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    margin: 10px 20px 0 0;

    // 缩宽
    .apple {
      width: 852px;
      height: 100%;
      /* margin: 30px auto; */
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .appleLeft {
        width: 750px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .appleLefts/deep/img {
          width: 700px;
          height: auto;
          margin-top: 25px;
        }
      }

      .appleRight {
        width: 430px;
        height: 1292px;
        background: #ffffff;

        .appleRightTop {
          width: auto;
          height: 20px;
          margin: 16px 22px;
          border-left: 6px solid #3855f9;
          padding: 0px 12px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e7e7e;
        }

        img {
          width: 376px;
          height: 209px;
          margin-top: 25px;
          margin-left: 27px;
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}

.content {
  display: flex;
  width: 1200px;
  margin: 20px calc(50% - 600px);

  .box-content {
    width: 1200px;
  }

  .youlove {
    margin-top: 10px;
    width: 328px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0px;
  }
}

.el-popover {
  overflow: hidden;
  padding: 0px;
}

.mask_layer {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terbox {
  width: 28px !important;
  height: 28px !important;
  cursor: pointer;

  .ter-img {
    width: 28px;
    width: 28px;
    display: grid;
  }
}
</style>
